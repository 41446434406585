import type { SVGProps } from 'react'

const LinkOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="link_off">
      <mask id="mask0_2332_45543" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="82" height="82">
        <rect id="Bounding box" width="82" height="82" fill="currentColor"/>
      </mask>
      <g mask="url(#mask0_2332_45543)">
        <path
          id="link_off_2"
          d="M65.7648 56.2078L60.6398 50.912C62.9176 50.2856 64.7683 49.0755 66.1919 47.2818C67.6155 45.488 68.3273 43.3953 68.3273 41.0036C68.3273 38.1564 67.3308 35.7363 65.3378 33.7432C63.3447 31.7502 60.9246 30.7536 58.0773 30.7536H44.4107V23.9203H58.0773C62.8037 23.9203 66.8326 25.5859 70.1638 28.9172C73.495 32.2484 75.1607 36.2773 75.1607 41.0036C75.1607 44.2495 74.3207 47.2391 72.6409 49.9724C70.961 52.7057 68.669 54.7842 65.7648 56.2078ZM54.1482 44.4203L47.3148 37.587H54.6607V44.4203H54.1482ZM67.644 77.2203L4.77734 14.3536L9.56068 9.57031L72.4273 72.437L67.644 77.2203ZM37.5773 58.087H23.9107C19.1843 58.087 15.1555 56.4214 11.8242 53.0901C8.49297 49.7589 6.82734 45.73 6.82734 41.0036C6.82734 37.0745 8.02318 33.5724 10.4148 30.4974C12.8065 27.4224 15.8815 25.4009 19.6398 24.4328L25.9607 30.7536H23.9107C21.0635 30.7536 18.6433 31.7502 16.6503 33.7432C14.6572 35.7363 13.6607 38.1564 13.6607 41.0036C13.6607 43.8509 14.6572 46.271 16.6503 48.2641C18.6433 50.2571 21.0635 51.2536 23.9107 51.2536H37.5773V58.087ZM27.3273 44.4203V37.587H32.8794L39.6273 44.4203H27.3273Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)

export default LinkOff