import { lazy } from 'react'

export const icons = {
	adminMeds: lazy(async () => import('./Icons/AdminMeds')),
	/**
	 * @deprecated
	 */
	ambulance: lazy(async () => import('./Icons/Ambulance')),
	/**
	 * @deprecated use arrowLeft instead
	 */
	arrowBack: lazy(async () => import('./Icons/ArrowLeft')),
	arrowDown: lazy(async () => import('./Icons/ArrowDown')),
	/**
	 * @deprecated use arrowRight instead
	 */
	arrowFoward: lazy(async () => import('./Icons/ArrowFoward')),
	arrowLeft: lazy(async () => import('./Icons/ArrowLeft')),
	arrowRight: lazy(async () => import('./Icons/ArrowRight')),
	arrowUp: lazy(async () => import('./Icons/ArrowUp')),
	arrowUpDown: lazy(async () => import('./Icons/ArrowUpDown')),
	arrowUpRight: lazy(async () => import('./Icons/ArrowUpRight')),
	attachFile: lazy(async () => import('./Icons/AttachFile')),
	/**
	 * @deprecated
	 */
	barChart: lazy(async () => import('./Icons/BarChart')),
	bars: lazy(async () => import('./Icons/Bars')),
	/**
	 * @deprecated
	 */
	BiBookHeart: lazy(async () => import('./Icons/BiBookHeart')),
	/**
	 * @deprecated use Calendar
	 */
	BiCalendar: lazy(async () => import('./Icons/Calendar')),
	/**
	 * @deprecated use heart instead
	 */
	BiHeart: lazy(async () => import('./Icons/Heart')),
	/**
	 * @deprecated
	 */
	board: lazy(async () => import('./Icons/LabProfile')),
	book: lazy(async () => import('./Icons/Book')),
	bookmark: lazy(async () => import('./Icons/Bookmark')),
	brainCircuit: lazy(async () => import('./Icons/BrainCircuit')),
	briefcaseMedical: lazy(async () => import('./Icons/BriefcaseMedical')),
	calendar: lazy(async () => import('./Icons/Calendar')),
	calendarClock: lazy(async () => import('./Icons/CalendarClock')),
	calendarHeart: lazy(async () => import('./Icons/CalendarHeart')),
	call: lazy(async () => import('./Icons/Call')),
	callEnd: lazy(async () => import('./Icons/CallEnd')),
	cancel: lazy(async () => import('./Icons/Cancel')),
	cancelFilled: lazy(async () => import('./Icons/CancelFilled')),
	cancelOutline: lazy(async () => import('./Icons/CancelOutline')),
	camera: lazy(async () => import('./Icons/Camera')),
	caretDown: lazy(async () => import('./Icons/CaretDown')),
	cartPlus: lazy(async () => import('./Icons/CartPlus')),
	chat: lazy(async () => import('./Icons/Chat')),
	chatFilled: lazy(async () => import('./Icons/ChatFilled')),
	chatBubble: lazy(async () => import('./Icons/ChatBubble')),
	check: lazy(async () => import('./Icons/Check')),
	checkFilled: lazy(async () => import('./Icons/CheckFilled')),
	checkOutline: lazy(async () => import('./Icons/CheckOutline')),
	chevronDown: lazy(async () => import('./Icons/ChevronDown')),
	chevronLeft: lazy(async () => import('./Icons/ChevronLeft')),
	chevronRight: lazy(async () => import('./Icons/ChevronRight')),
	chevronUp: lazy(async () => import('./Icons/ChevronUp')),
	/**
	 * @deprecated use schedule instead
	 */
	clock: lazy(async () => import('./Icons/Schedule')),
	close: lazy(async () => import('./Icons/Close')),
	/**
	 * @deprecated use cancelOutline instead
	 */
	closeCircle: lazy(async () => import('./Icons/CancelOutline')),
	closedFolder: lazy(async () => import('./Icons/FolderClosed')),
	computer: lazy(async () => import('./Icons/Computer')),
	deaf: lazy(async () => import('./Icons/Deaf')),
	delete: lazy(async () => import('./Icons/Delete')),
	dengue: lazy(async () => import('./Icons/Dengue')),
	description: lazy(async () => import('./Icons/Description')),
	diagnosis: lazy(async () => import('./Icons/Diagnosis')),
	diversity: lazy(async () => import('./Icons/Diversity')),
	documentOk: lazy(async () => import('./Icons/DocumentOk')),
	done: lazy(async () => import('./Icons/Done')),
	doneAll: lazy(async () => import('./Icons/DoneAll')),
	download: lazy(async () => import('./Icons/Download')),
	drop: lazy(async () => import('./Icons/Drop')),
	/**
	 * @deprecated use arrowDown instead
	 */
	dropdown: lazy(async () => import('./Icons/Dropdown')),
	edit: lazy(async () => import('./Icons/Edit')),
	ellipsisV: lazy(async () => import('./Icons/EllipsisV')),
	email: lazy(async () => import('./Icons/Email')),
	emailFillOff: lazy(async () => import('./Icons/EmailFillOff')),
	emailFillOn: lazy(async () => import('./Icons/EmailFillOn')),
	empathize: lazy(async () => import('./Icons/Empathize')),
	/**
	 * @deprecated use email instead
	 */
	envelope: lazy(async () => import('./Icons/Email')),
	/**
	 * @deprecated use emailFillOn instead
	 */
	envelopeFill: lazy(async () => import('./Icons/EmailFillOn')),
	error: lazy(async () => import('./Icons/Error')),
	errorOutline: lazy(async () => import('./Icons/ErrorOutline')),
	eventUpcoming: lazy(async () => import('./Icons/EventUpcoming')),
	/**
	 * @deprecated use visibility instead
	 */
	eye: lazy(async () => import('./Icons/Visibility')),
	/**
	 * @deprecated use visibilityOff instead
	 */
	eyeSlash: lazy(async () => import('./Icons/VisibilityOff')),
	facebook: lazy(async () => import('./Icons/Facebook')),
	family: lazy(async () => import('./Icons/Family')),
	file: lazy(async () => import('./Icons/File')),
	fileMedical: lazy(async () => import('./Icons/FileMedical')),
	folderOpen: lazy(async () => import('./Icons/FolderOpen')),
	greenCheckCircle: lazy(async () => import('./Icons/GreenCheckCircle')),
	graduateCap: lazy(async () => import('./Icons/GraduateCap')),
	handCoins: lazy(async () => import('./Icons/HandCoins')),
	happyFace: lazy(async () => import('./Icons/HappyFace')),
	healthMetrics: lazy(async () => import('./Icons/HealthMetrics')),
	healthSafety: lazy(async () => import('./Icons/HealthSafety')),
	heart: lazy(async () => import('./Icons/Heart')),
	heartFilled: lazy(async () => import('./Icons/HeartFilled')),
	heartPulse: lazy(async () => import('./Icons/HeartPulse')),
	heartPulse2: lazy(async () => import('./Icons/HeartPulse2')),
	history: lazy(async () => import('./Icons/History')),
	holdingHeart: lazy(async () => import('./Icons/HoldingHeart')),
	/**
	 * @deprecated use house
	 */
	home: lazy(async () => import('./Icons/House')),
	homeHealth: lazy(async () => import('./Icons/HomeHealth')),
	house: lazy(async () => import('./Icons/House')),
	hospital: lazy(async () => import('./Icons/Hospital')),
	image: lazy(async () => import('./Icons/Image')),
	info: lazy(async () => import('./Icons/Info')),
	instagram: lazy(async () => import('./Icons/Instagram')),
	jpg: lazy(async () => import('./Icons/JPG')),
	labProfile: lazy(async () => import('./Icons/LabProfile')),
	/**
	 * @deprecated use computer
	 */
	laptop: lazy(async () => import('./Icons/Computer')),
	laptopMedical: lazy(async () => import('./Icons/LaptopMedical')),
	link: lazy(async () => import('./Icons/Link')),
	linkedin: lazy(async () => import('./Icons/Linkedin')),
	listUl: lazy(async () => import('./Icons/ListUl')),
	lock: lazy(async () => import('./Icons/Lock')),
	loginCircle: lazy(async () => import('./Icons/LoginCircle')),
	logout: lazy(async () => import('./Icons/Logout')),
	loyalty: lazy(async () => import('./Icons/Loyalty')),
	lungs: lazy(async () => import('./Icons/Lungs')),
	magicStar: lazy(async () => import('./Icons/MagicStar')),
	medicalInfo: lazy(async () => import('./Icons/MedicalInfo')),
	menuHorizontal: lazy(async () => import('./Icons/MenuHorizontal')),
	menuVertical: lazy(async () => import('./Icons/MenuVertical')),
	message: lazy(async () => import('./Icons/Message')),
	microphone: lazy(async () => import('./Icons/Microphone')),
	microphoneOff: lazy(async () => import('./Icons/MicrophoneOff')),
	monetization: lazy(async () => import('./Icons/Monetization')),
	money: lazy(async () => import('./Icons/Money')),
	moving: lazy(async () => import('./Icons/Moving')),
	navigateBefore: lazy(async () => import('./Icons/NavigateBefore')),
	navigateNext: lazy(async () => import('./Icons/NavigateNext')),
	newspaper: lazy(async () => import('./Icons/Newspaper')),
	note: lazy(async () => import('./Icons/Note')),
	/**
	 * @deprecated use notificationOff
	 */
	notifications: lazy(async () => import('./Icons/NotificationOff')),
	notificationFilledOff: lazy(
		async () => import('./Icons/NotificationFilledOff'),
	),
	notificationFilledOn: lazy(
		async () => import('./Icons/NotificationFilledOn'),
	),
	notificationOff: lazy(async () => import('./Icons/NotificationOff')),
	notificationOn: lazy(async () => import('./Icons/NotificationOn')),
	nurse: lazy(async () => import('./Icons/Nurse')),
	pause: lazy(async () => import('./Icons/Pause')),
	pdf: lazy(async () => import('./Icons/PDF')),
	/**
	 * @deprecated use edit
	 */
	pencil: lazy(async () => import('./Icons/Edit')),
	pills: lazy(async () => import('./Icons/Pills')),
	png: lazy(async () => import('./Icons/PNG')),
	play: lazy(async () => import('./Icons/Play')),
	plus: lazy(async () => import('./Icons/Plus')),
	plusCircle: lazy(async () => import('./Icons/PlusCircle')),
	prescription: lazy(async () => import('./Icons/Prescription')),
	pulse: lazy(async () => import('./Icons/Pulse')),
	questionCircle: lazy(async () => import('./Icons/QuestionCircle')),
	quoteLeft: lazy(async () => import('./Icons/QuoteLeft')),
	quoteRight: lazy(async () => import('./Icons/QuoteRight')),
	schedule: lazy(async () => import('./Icons/Schedule')),
	science: lazy(async () => import('./Icons/Science')),
	search: lazy(async () => import('./Icons/Search')),
	/**
	 * @deprecated use shield
	 */
	security: lazy(async () => import('./Icons/Security')),
	sendMessage: lazy(async () => import('./Icons/SendMessage')),
	sendPlane: lazy(async () => import('./Icons/SendPlane')),
	/**
	 * @deprecated use sendPlane
	 */
	sendPlane2: lazy(async () => import('./Icons/SendPlane')),
	share: lazy(async () => import('./Icons/Share')),
	shield: lazy(async () => import('./Icons/Shield')),
	sortByAlpha: lazy(async () => import('./Icons/SortByAlpha')),
	sparking: lazy(async () => import('./Icons/Sparking')),
	starFill: lazy(async () => import('./Icons/StarFill')),
	statMinus: lazy(async () => import('./Icons/StatMinus')),
	stethoscope: lazy(async () => import('./Icons/Stethoscope')),
	swapVert: lazy(async () => import('./Icons/SwapVert')),
	syringe: lazy(async () => import('./Icons/Syringe')),
	testTube: lazy(async () => import('./Icons/TestTube')),
	thumbsDown: lazy(async () => import('./Icons/ThumbsDown')),
	thumbsDownFill: lazy(async () => import('./Icons/ThumbsDownFill')),
	thumbsUp: lazy(async () => import('./Icons/ThumbsUp')),
	thumbsUpFill: lazy(async () => import('./Icons/ThumbsUpFill')),
	/**
	 * @deprecated use delete
	 */
	trash: lazy(async () => import('./Icons/Delete')),
	trendingDown: lazy(async () => import('./Icons/TrendingDown')),
	trendingUp: lazy(async () => import('./Icons/TrendingUp')),
	upload: lazy(async () => import('./Icons/Upload')),
	user: lazy(async () => import('./Icons/User')),
	userCircle: lazy(async () => import('./Icons/UserCircle')),
	userInjured: lazy(async () => import('./Icons/UserInjured')),
	userMd: lazy(async () => import('./Icons/UserMd')),
	userOutline: lazy(async () => import('./Icons/UserOutline')),
	userPlus: lazy(async () => import('./Icons/UserPlus')),
	videocall: lazy(async () => import('./Icons/Videocall')),
	videocallOff: lazy(async () => import('./Icons/VideocallOff')),
	/**
	 * @deprecated
	 */
	videoPlus: lazy(async () => import('./Icons/VideoPlus')),
	visibility: lazy(async () => import('./Icons/Visibility')),
	visibilityOff: lazy(async () => import('./Icons/VisibilityOff')),
	vitalSigns: lazy(async () => import('./Icons/VitalSigns')),
	warning: lazy(async () => import('./Icons/Warning')),
	warningOutline: lazy(async () => import('./Icons/WarningOutline')),
	whatsApp: lazy(async () => import('./Icons/WhatsApp')),
	world: lazy(async () => import('./Icons/World')),
	youtube: lazy(async () => import('./Icons/Youtube')),
	castScreen: lazy(async () => import('./Icons/CastScreen')),
	bigHome: lazy(async () => import('./Icons/Home')),
	train: lazy(async () => import('./Icons/Train')),
	turbo: lazy(async () => import('./Icons/Turbo')),
	filter: lazy(async () => import('./Icons/Filter')),
	uploadFile: lazy(async () => import('./Icons/UploadFile')),
	linkOff: lazy(async () => import('./Icons/LinkOff')),
}

/*

export const iconsMap = {
  ),
} as const satisfies Icons 
*/
export type IconsNames = keyof typeof icons

export const iconsNamesObject = Object.keys(
	icons,
) as IconsNames[] /* Esta hardcodeado el tipo pero es solo para la story de storybook*/
