import { Dispatch, ReactNode, SetStateAction, useRef } from "react";
import { useOnClickOutsideClose } from "./useOnClickOutsideClose";

/**
 * Componente que si clickeas fuera de su hijo, pune un set en false.
 * Usado para cerrar menus
 */
export default function OutsideCloser( { children, setOpen} : { children: ReactNode, setOpen: Dispatch<SetStateAction<boolean>>
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutsideClose(wrapperRef, setOpen);

  return <div ref={wrapperRef}>{children}</div>;
}